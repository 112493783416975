<template>
    <div class="box">
        <div class="video-box" :style="{ height: videoHeight + 'px'}">
            <video ref="videoPlayer" id="myVideo" :style="{ height: videoHeight + 'px',width: videoWeight + 'px'}"
                :src="video_list.userVideoPath" :id="video_details" loop controls :poster="video_list.userVideoImg"
                @error="videoErrorCallback" :enable-progress-gesture="gesture" @play="handlePlay"
                @pause="handlePause"></video>
        </div>
        <div class="video_box" ref="videoBox" :style="{ minHeight: backImgHeight + 'px',paddingBottom: 70 + 'px'}">
            <div>
                <div class="video_img_box">
                    <div class="video_img_flex" @click="downLoadVideo">
                        <div class="downLoadImg"></div>
                        <p class="video_img_text">{{$t('downLoad')}}</p>
                    </div>
                    <div class="video_img_flex" @click="userShare">
                        <div class="video_share"></div>
                        <p class="video_img_text">{{$t('share')}}</p>
                    </div>
                </div>
                <div class="video_box_back"></div>
                <div class="video_price">
                    <div class="video_name">{{video_list.userVideoName}}</div>
                </div>
            </div>
            <div class="video_text_back">
                <div class="video_text">{{video_list.userVideoText}}</div>
            </div>
        </div>
        <!-- <div class="boxs">
            <div class="videoBox">
                <div class="videoBoxBack"></div>
                <div class="video_price">
                    <div class="video_name">{{video_list.userVideoName}}</div>
                </div>
            </div>
            <div class="video_text_back">
                <div class="video_text">{{video_list.userVideoText}}</div>
            </div>
        </div> -->
    </div>
</template>
<script>
    import { Toast } from "vant";
    import "vant/lib/index.css";
    import OSS from 'ali-oss';
    import axios from 'axios'
    import base64 from '@/utils/userbase64.js'
    export default {
        components: {
        },
        data() {
            return {
                videoHeight: '',
                width: '',
                videoWeight: '',
                video_list: [],
                gesture: false,
                backImgHeight: 0,
                video_details: 1
            }
        },
        mounted() {
            // console.log(this.$route.query.videoId)
        },
        methods: {
            // 分享
            userShare() {
                // this.$router.push({
                //     path: `/user_video?videoUrl=${this.video_list.userVideoPath}
                //     &userVideoImgHeight=${this.$route.query.userVideoImgHeight}
                //     &userVideoImgWidth=${this.$route.query.userVideoImgWidth}
                //     &userVideoName=${this.video_list.userVideoName}
                //     &userVideoText=${this.video_list.userVideoText}`
                // })
                // return
                let type = null
                if (localStorage.getItem('h5_user_info') && this.$route.query.userModelDefault && !this.$route.query.shootUserModel) {
                    type = 2
                } else {
                    type = 1
                }
                let textarea = document.createElement("textarea"); // 创建textarea文本框
                textarea.value = `https://hzag.ustyle.cn/#/user_video?videoId=${this.$route.query.videoId}&videoType=${type}`;
                // textarea.value = `https://www.ustyle.cn/app/h5/index.html#/views/sharepage/share?id=${this.$route.query.videoId}&videoType=${type}`;
                textarea.readOnly = "readOnly";
                document.body.appendChild(textarea); // 追加元素
                textarea.select() // 选中文本内容
                let result = document.execCommand("copy"); // 执行浏览器复制命令
                textarea.remove();
                // console.log(base64.compress(textarea.value));
                // console.log(base64.decompress(textarea.value));
                this.$confirm({
                    title: '提示',
                    message: this.$t('shareText'),
                    btn: {
                        okText: '确定',
                        cancelText: '取消'
                    }
                })
            },
            convertToBlob(url) {
                return fetch(url)
                    .then(response => response.blob())
                    .then(blob => {
                        const filename = url.substring(url.lastIndexOf('/') + 1);
                        return new File([blob], filename, { type: 'video/mp4' });
                    });
            },
            // 下载
            async downLoadVideo() {
                const path = this.video_list.userVideoPath.replace('https://ustyle-dev.oss-cn-hangzhou.aliyuncs.com/', '');
                console.log(path);
                const client = new OSS({
                    region: 'oss-cn-hangzhou',
                    accessKeyId: 'LTAI4GA4rwWznmo3E4fxzGgG',
                    accessKeySecret: 'cUyjK3pCgg1nfYp0hR9gVhzjVBug3X',
                    bucket: 'ustyle-dev',
                });
                let url = client.signatureUrl(path, {
                    response: {
                        'content-disposition': `attachment; filename="${encodeURIComponent(path)}`
                    }
                })
                const link = document.createElement('a'); // 创建<a>标签
                link.href = url; // 设置下载链接
                link.download = 'filename.mp4'; // 设置文件名
                document.body.appendChild(link); // 将<a>标签添加到页面中
                link.click(); // 模拟点击下载链接
                document.body.removeChild(link); // 下载完成后移除<a>标签
                window.URL.revokeObjectURL(url); // 释放URL对象资源
                this.$confirm({
                    title: '提示',
                    message: this.$t('downLoadSuccess'),
                    btn: {
                        okText: '确定',
                        cancelText: '取消'
                    }
                })
            },

            // 视频模板详情
            async getUserVideoData() {
                let data, res
                if (localStorage.getItem("h5_user_info") && this.$route.query.userModelDefault) {
                    data = {
                        language: this.$i18n.locale,
                        email: JSON.parse(localStorage.getItem('h5_user_info')).userEmail ? JSON.parse(localStorage.getItem('h5_user_info')).userEmail : '',
                        phone: JSON.parse(localStorage.getItem('h5_user_info')).userPhone ? JSON.parse(localStorage.getItem('h5_user_info')).userPhone : '',
                        videoId: this.$route.query.videoId
                    }
                    res = await this.$http.post('getVideoData', data)
                } else {
                    data = {
                        language: this.$i18n.locale,
                        videoTemplateId: this.$route.query.videoId
                    }
                    res = await this.$http.post('getVideoTemplateData', data)
                }
                console.log(res)
                if (res.resultCode) {
                    this.video_list = res.data;
                    var name = "download";

                }
            },
            handlePlay() {
                this.gesture = true
            },
            handlePause() {
                this.gesture = false
            },
            videoErrorCallback(e) {
                if (this.gesture) {
                    Toast.fail(e.target.errMsg)
                    setTimeout(() => {
                        let video = document.getElementById('video_details');
                        video.onerror = function () { // 这里要用onerror
                            videoItem.status = false;
                            video.src = this.video_list.userVideoPath;
                            video.onerror = null; // 避免加载失败后陷入死循环
                        }
                        video.src = video_list.userVideoPath;
                    }, 100);
                }
            },
        },
        created() {

        },
        mounted() {
            const { userVideoImgHeight, userVideoImgWidth } = this.$route.query;
            console.log(userVideoImgHeight, userVideoImgWidth)
            let windowWidth = window.innerWidth;
            let windowHeight = window.innerHeight;
            let s = (windowWidth / this.$route.query.userVideoImgWidth).toFixed(4);
            console.log('window.innerHeight' + windowHeight)
            this.videoWeight = windowWidth;
            console.log('window.innerWidth' + windowWidth)
            this.videoHeight = this.$route.query.userVideoImgHeight * s;
            // 宽视频
            if (parseInt(userVideoImgWidth) > parseInt(userVideoImgHeight)) {
                const videoBox = this.$refs.videoBox.offsetHeight;
                console.log(videoBox)
                // this.backImgHeight = (windowHeight - this.videoHeight - videoBox) / 2;
                this.backImgHeight = windowHeight - this.videoHeight
                console.log(this.backImgHeight)
                console.log('短视频')
            } else { //长视频
                const videoBox = this.$refs.videoBox.offsetHeight;
                console.log(this.$refs.videoBox)
                this.backImgHeight = (windowHeight - videoBox) / 2;
                console.log(this.backImgHeight)
                console.log("长视频")
            }
            this.getUserVideoData()
        },
    }
</script>
<style scoped>
    .box {
        width: 100%;
        min-height: 100%;
    }

    .boxs {
        width: 100%;
        margin: auto;
        padding-bottom: 70px;
    }

    .video-box {
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
    }

    #myVideo {
        width: 100%;
        height: 100%;
    }

    .videoBox {
        width: 100%;
        height: 73px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #ffffff;
        position: relative;
        background-image: url('../assets/img/video.png');
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-background-size: cover;
        -o-background-size: cover;
        background-position: center;
    }

    .video_box {
        background-image: url('../assets/img/video_back.png');
        background-size: cover;
        position: relative;
        height: max-content;
        position: relative;
        /* padding: 24px 0; */
    }

    .video_img_box {
        width: 100%;
        /* height: 40px; */
        height: max-content;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
    }

    .video_img_flex {
        width: 40px;
        height: max-content;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        margin-top: 16px;
    }

    .video_img_text {
        margin-top: 4px;
        font-size: 12px;
        color: #ffffff;
    }

    .video_box_back {
        width: 100%;
        height: 100%;
        /* position: absolute; */
        /* background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0) 74%, #fff 98%); */
    }

    .videoBoxBack {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0) 78%, #f2f2f2 98%);
    }

    .video_box_details {
        width: 90%;
        margin: 0 auto 24px auto;
        white-space: pre-wrap;
        letter-spacing: 2px;
        color: #000000;
        font-size: 16px;
        display: flex;
        flex-wrap: wrap;
    }

    .video_text_back {
        width: 326px;
        background-image: url('../assets/img/text_back.png');
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-background-size: cover;
        -o-background-size: cover;
        background-position: center;
        /* box-shadow: 5px 0px 7px 0px rgb(33 37 41 / 50%); */
        padding: 14px 0;
        border-radius: 5px;
        margin: 0 auto;
        /* position: absolute;
        left: 50%;
        top: 77px;
        margin-left: -163px; */
    }

    .video_text {
        width: 294px;
        margin: 0 auto;
        font-size: 12px;
        color: #ffffff;
        white-space: pre-line;
        /* font-weight: 900; */
        line-height: 19px;
        letter-spacing: 1.5px;
    }

    .video_word_title {
        color: #000000;
        letter-spacing: 2px;
        font-size: 21px;
        padding: 15px 0;
    }

    .box_title_flex_between {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        color: #000000;
        font-size: 15px;
        padding-bottom: 16px;
        width: 90%;
        margin: 0 auto;
    }

    .box_title_child_flex_between {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .box_title_child_flex_between>img {
        width: 30px;
        height: 30px;
        padding-right: 7px;
    }


    .video_price {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-around;
        z-index: 999;
        /* padding-top: 20px; */
    }

    .video_name::after {
        width: 80%;
        margin: 4px auto;
        content: "";
        display: block;
        background: linear-gradient(to right, rgba(40, 150, 252, 1), rgba(255, 255, 255, 0));
        width: 100%;
        height: 1px;
    }

    .video_name {
        color: #ffffff;
        font-size: 21px;
        width: 342px;
        margin: 0 auto;
        padding: 4px 0;
    }

    .downLoadImg {
        width: 24px !important;
        height: 24px !important;
        background-image: url('../assets/img/downLoad.png');
        background-size: cover;
    }

    .video_share {
        width: 24px;
        height: 24px;
        background-image: url('../assets/img/share.png');
        background-size: 100% 100%;
    }

    .video_price_flex {
        display: flex;
        flex-wrap: wrap;
    }

    .video_flex {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        color: #000000;
    }

    .icon_padd {
        padding-right: 5px;
    }

    .iconfont {
        font-size: 21px;
    }

    .icon_color_blue {
        color: #0067FF;
        font-size: 21px;
    }

    .collectPng {
        width: 24px;
        height: 24px;
    }

    .not_collect {
        width: 24px;
        height: 24px;
    }

    .video_detail_text {
        font-size: 14px;
    }
</style>